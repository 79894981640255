import React, { useState, useEffect } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { agate } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { camelToKebab, generateComponentsCss } from './utils';
import { useTheme } from './ThemeContext';
import defaultValues from './theme.json';

function PreviewComponent({ Component, ComponentName, Id, markup }) {
  const { theme = {}, setTheme } = useTheme();
  const [props, setProps] = useState(() => {
    const savedProps = localStorage.getItem('props');
    return savedProps ? JSON.parse(savedProps) : theme.props || {};
  });
  const [localProps, setLocalProps] = useState({});
  const [activeDeviceButton, setActiveDeviceButton] = useState('Large devices');
  const [activePropertyButton, setActivePropertyButton] = useState();
  const localStorageValue = localStorage.getItem(ComponentName);
  const localStorageObject = localStorageValue ? JSON.parse(localStorageValue) : {};
  const [selectedOptions, setSelectedOptions] = useState(localStorageObject);
  const [selectedField, setselectedField] = useState({});
  const [selectedTitle, setSelectedTitle] = useState(() => {
    const initialTitles = {};
    for (const key in selectedOptions) {
      if (selectedOptions[key]) {
        initialTitles[key] = selectedOptions[key].replace('var(--', '').replace(')', '');
      }
    }
    return initialTitles;
  });

  useEffect(() => {
    const newTitles = {};
    for (const key in selectedOptions) {
      if (selectedOptions[key]) {
        newTitles[key] = selectedOptions[key].replace('var(--', '').replace(')', '');
      }
    }
    setSelectedTitle(newTitles);
  }, [selectedOptions]);

  useEffect(() => {
    const localStorageValue = localStorage.getItem(ComponentName);
    const localStorageObject = localStorageValue ? JSON.parse(localStorageValue) : {};
    setSelectedOptions(localStorageObject);
  }, [Component, ComponentName]);

  useEffect(() => {}, [ComponentName]);
  function setSliderBackground() {
    const sliderEls = document.querySelectorAll('.ui-slider');
    sliderEls.forEach((sliderEl) => {
      const tempSliderValue = sliderEl.value ? sliderEl.value : 0;
      const progress = (tempSliderValue / sliderEl.max) * 100;
      sliderEl.style.background = `linear-gradient(to right, #7f56d9 ${progress}%, #eaecf0 ${progress}%)`;
    });
  }

  // useEffect(() => {
  //   const initialActivePropertyButton = propertyButtonNames.find((name) =>
  //     Object.keys(props).some((key) => key.toLowerCase().includes(name.toLowerCase())),
  //   );

  //   setTimeout(setSliderBackground, 10);
  //   setActivePropertyButton(initialActivePropertyButton || propertyButtonNames[0]);
  // }, [props, activeDeviceButton]);

  useEffect(() => {
    const propertyButtonNames = [
      'Color',
      'Background',
      'Border',
      'Size',
      'Line-Height',
      'Weight',
      'Space',
      'Container',
      'Gap',
      'Height',
      'Font',
      'Outline',
      'Width',
    ];
    // Finden Sie das erste verfügbare PropertyButton
    const firstAvailablePropertyButton = propertyButtonNames.find((name) =>
      Object.keys(props).some(
        (key) =>
          key.toLowerCase().includes(name.toLowerCase()) &&
          ((key.includes('SmallBreakpoint') && activeDeviceButton === 'Small devices') ||
            (key.includes('MediumBreakpoint') && activeDeviceButton === 'Medium devices') ||
            (!key.includes('SmallBreakpoint') &&
              !key.includes('MediumBreakpoint') &&
              activeDeviceButton === 'Large devices')),
      ),
    );

    setActivePropertyButton(firstAvailablePropertyButton);
    setTimeout(setSliderBackground, 10);
  }, [props, activeDeviceButton]);

  const DeviceButton = ({ name }) => (
    <button
      className={`ui-button ${activeDeviceButton === name ? 'active' : ''}`}
      onClick={() => handleToggle(name)}
    >
      {name}
    </button>
  );

  const PropertyButton = ({ name }) => (
    <button
      className={`ui-button ${activePropertyButton === name ? 'active' : ''}`}
      onClick={() => handlePropertyToggle(name)}
    >
      {name}
    </button>
  );

  const handleDeviceButtonClick = (device) => {
    setActiveDeviceButton(device);
  };

  const handlePropertyToggle = (name) => {
    setActivePropertyButton(name);
    setTimeout(setSliderBackground, 0);
  };
  const handleToggle = (name) => {
    setActiveDeviceButton(name);
    setTimeout(setSliderBackground, 0);
  };

  useEffect(() => {
    const storedProps = localStorage.getItem(`${ComponentName}`);
    if (storedProps) {
      const parsedProps = JSON.parse(storedProps);
      setLocalProps((prevProps) => {
        return {
          ...prevProps,
          [ComponentName]: parsedProps,
        };
      });
    }
  }, [ComponentName]);

  function handleChange(event) {
    const { name, value } = event.target;
    const unit = theme[ComponentName].values[name]?.unit || '';

    if (event.isCustomSelect) {
      setSelectedOptions((prevOptions) => {
        const newOptions = { ...prevOptions, [name]: value };
        newOptions[name] = localStorageObject[name] ? localStorageObject[name] : value;
        return newOptions;
      });
    }

    setLocalProps((prevProps) => {
      const newProps = {
        ...prevProps,
        [ComponentName]: {
          ...prevProps[ComponentName],
          [name]: unit ? value + unit : value,
        },
      };

      if (unit) {
        localStorage.setItem(
          `${ComponentName}`,
          JSON.stringify({ ...newProps[ComponentName], [name]: value + unit }),
        );
        document.documentElement.style.setProperty(`--${camelToKebab(name)}`, value + unit);
      } else {
        localStorage.setItem(`${ComponentName}`, JSON.stringify(newProps[ComponentName]));
      }

      return newProps;
    });
    setSliderBackground();
  }

  useEffect(() => {
    if (JSON.stringify(theme.props) !== JSON.stringify(localProps)) {
      setTheme({ ...theme, props: localProps });
    }
  }, [localProps, setTheme, theme]);

  useEffect(() => {
    const theme = defaultValues.theme;
    const themeProps = Object.keys(theme[ComponentName].values).reduce((acc, key) => {
      const localStorageValue = localStorage.getItem(ComponentName);
      const localStorageObject = localStorageValue ? JSON.parse(localStorageValue) : {};
      acc[key] = localStorageObject[key]
        ? localStorageObject[key]
        : theme[ComponentName].values[key].default;
      return acc;
    }, {});

    // Speichern Sie das gesamte Objekt im localStorage
    localStorage.setItem(ComponentName, JSON.stringify(themeProps));

    setProps(themeProps);
    setTheme(theme);
    if (theme.props) {
      generateComponentsCss(theme.props);
    }
  }, [Component, setTheme, ComponentName]);

  const getOptions = (key, themeObj = theme, prefix = '') => {
    let options = [];
    if (
      (key.toLowerCase().includes('color') && theme.Colors) ||
      (key.toLowerCase().includes('background') && theme.Colors)
    ) {
      options = Object.entries(theme.Colors).flatMap(([colorKey, colorValue]) => {
        if (typeof colorValue === 'object') {
          return Object.keys(colorValue).map(
            (subKey) => `${subKey}: var(--${camelToKebab(subKey)})`,
          );
        } else {
          return `${colorKey}: var(--${camelToKebab(colorKey)})`;
        }
      });
    } else if (key.toLowerCase().includes('weight')) {
      options = Object.keys(theme.Typography.values)
        .filter((key) => key.includes('weight'))
        .map((weightKey) => `${weightKey}: var(--${camelToKebab(weightKey)})`);
    } else if (key.toLowerCase().includes('size')) {
      options = Object.keys(theme.Sizes).map(
        (sizeKey) => `${sizeKey}: var(--size-${camelToKebab(sizeKey)})`,
      );
    }
    return options;
  };

  useEffect(() => {
    setSliderBackground();
  }, [props, localProps]);

  const handleOptionClick = (key, value) => {
    setselectedField(key);
    handleChange({
      isCustomSelect: true,
      target: {
        name: key,
        value: value,
      },
    });
  };

  return (
    <div>
      <div
        className={`ui-component-settings-actions 
       ${
         activeDeviceButton === 'Small devices'
           ? 'ui-component-preview-small'
           : activeDeviceButton === 'Medium devices'
           ? 'ui-component-preview-medium'
           : 'ui-component-preview-large'
       }`}
      >
        <DeviceButton
          onClick={() => handleDeviceButtonClick('Small devices')}
          name="Large devices"
        />
        <DeviceButton
          onClick={() => handleDeviceButtonClick('Medium devices')}
          name="Medium devices"
        />
        <DeviceButton
          onClick={() => handleDeviceButtonClick('Large devices')}
          name="Small devices"
        />
      </div>
      <Component {...props} Id={Id} />
      <div className="ui-component-wrap">
        <div className={`ui-component-group`}>
          {Object.keys(props).some(
            (key) =>
              key.includes('color') &&
              ((key.includes('SmallBreakpoint') && activeDeviceButton === 'Small devices') ||
                (key.includes('MediumBreakpoint') && activeDeviceButton === 'Medium devices') ||
                (!key.includes('SmallBreakpoint') &&
                  !key.includes('MediumBreakpoint') &&
                  activeDeviceButton === 'Large devices')),
          ) && <PropertyButton name="Color" />}
          {Object.keys(props).some(
            (key) =>
              key.includes('background') &&
              ((key.includes('SmallBreakpoint') && activeDeviceButton === 'Small devices') ||
                (key.includes('MediumBreakpoint') && activeDeviceButton === 'Medium devices') ||
                (!key.includes('SmallBreakpoint') &&
                  !key.includes('MediumBreakpoint') &&
                  activeDeviceButton === 'Large devices')),
          ) && <PropertyButton name="Background" />}

          {Object.keys(props).some(
            (key) =>
              key.includes('border') &&
              ((key.includes('SmallBreakpoint') && activeDeviceButton === 'Small devices') ||
                (key.includes('MediumBreakpoint') && activeDeviceButton === 'Medium devices') ||
                (!key.includes('SmallBreakpoint') &&
                  !key.includes('MediumBreakpoint') &&
                  activeDeviceButton === 'Large devices')),
          ) && <PropertyButton name="Border" />}
          {Object.keys(props).some(
            (key) =>
              key.includes('borderRadius') &&
              ((key.includes('SmallBreakpoint') && activeDeviceButton === 'Small devices') ||
                (key.includes('MediumBreakpoint') && activeDeviceButton === 'Medium devices') ||
                (!key.includes('SmallBreakpoint') &&
                  !key.includes('MediumBreakpoint') &&
                  activeDeviceButton === 'Large devices')),
          ) && <PropertyButton name="Border-radius" />}
          {Object.keys(props).some(
            (key) =>
              key.includes('Outline') &&
              ((key.includes('SmallBreakpoint') && activeDeviceButton === 'Small devices') ||
                (key.includes('MediumBreakpoint') && activeDeviceButton === 'Medium devices') ||
                (!key.includes('SmallBreakpoint') &&
                  !key.includes('MediumBreakpoint') &&
                  activeDeviceButton === 'Large devices')),
          ) && <PropertyButton name="Outline" />}
          {Object.keys(props).some(
            (key) =>
              key.includes('size') &&
              ((key.includes('SmallBreakpoint') && activeDeviceButton === 'Small devices') ||
                (key.includes('MediumBreakpoint') && activeDeviceButton === 'Medium devices') ||
                (!key.includes('SmallBreakpoint') &&
                  !key.includes('MediumBreakpoint') &&
                  activeDeviceButton === 'Large devices')),
          ) && <PropertyButton name="Size" />}
          {Object.keys(props).some(
            (key) =>
              key.includes('lineHeight') &&
              ((key.includes('SmallBreakpoint') && activeDeviceButton === 'Small devices') ||
                (key.includes('MediumBreakpoint') && activeDeviceButton === 'Medium devices') ||
                (!key.includes('SmallBreakpoint') &&
                  !key.includes('MediumBreakpoint') &&
                  activeDeviceButton === 'Large devices')),
          ) && <PropertyButton name="Line-height" />}
          {Object.keys(props).some(
            (key) =>
              key.includes('weight') &&
              ((key.includes('SmallBreakpoint') && activeDeviceButton === 'Small devices') ||
                (key.includes('MediumBreakpoint') && activeDeviceButton === 'Medium devices') ||
                (!key.includes('SmallBreakpoint') &&
                  !key.includes('MediumBreakpoint') &&
                  activeDeviceButton === 'Large devices')),
          ) && <PropertyButton name="Weight" />}
          {Object.keys(props).some(
            (key) =>
              key.includes('font') &&
              ((key.includes('SmallBreakpoint') && activeDeviceButton === 'Small devices') ||
                (key.includes('MediumBreakpoint') && activeDeviceButton === 'Medium devices') ||
                (!key.includes('SmallBreakpoint') &&
                  !key.includes('MediumBreakpoint') &&
                  activeDeviceButton === 'Large devices')),
          ) && <PropertyButton name="Font" />}
          {Object.keys(props).some(
            (key) =>
              key.includes('space') &&
              ((key.includes('SmallBreakpoint') && activeDeviceButton === 'Small devices') ||
                (key.includes('MediumBreakpoint') && activeDeviceButton === 'Medium devices') ||
                (!key.includes('SmallBreakpoint') &&
                  !key.includes('MediumBreakpoint') &&
                  activeDeviceButton === 'Large devices')),
          ) && <PropertyButton name="Space" />}
          {Object.keys(props).some(
            (key) =>
              key.includes('container') &&
              ((key.includes('SmallBreakpoint') && activeDeviceButton === 'Small devices') ||
                (key.includes('MediumBreakpoint') && activeDeviceButton === 'Medium devices') ||
                (!key.includes('SmallBreakpoint') &&
                  !key.includes('MediumBreakpoint') &&
                  activeDeviceButton === 'Large devices')),
          ) && <PropertyButton name="Container" />}
          {Object.keys(props).some(
            (key) =>
              key.includes('gap') &&
              ((key.includes('SmallBreakpoint') && activeDeviceButton === 'Small devices') ||
                (key.includes('MediumBreakpoint') && activeDeviceButton === 'Medium devices') ||
                (!key.includes('SmallBreakpoint') &&
                  !key.includes('MediumBreakpoint') &&
                  activeDeviceButton === 'Large devices')),
          ) && <PropertyButton name="Gap" />}
          {Object.keys(props).some(
            (key) =>
              key.includes('minHeight') &&
              ((key.includes('SmallBreakpoint') && activeDeviceButton === 'Small devices') ||
                (key.includes('MediumBreakpoint') && activeDeviceButton === 'Medium devices') ||
                (!key.includes('SmallBreakpoint') &&
                  !key.includes('MediumBreakpoint') &&
                  activeDeviceButton === 'Large devices')),
          ) && <PropertyButton name="Min-height" />}
          {Object.keys(props).some(
            (key) =>
              key.includes('maxWidth') &&
              ((key.includes('SmallBreakpoint') && activeDeviceButton === 'Small devices') ||
                (key.includes('MediumBreakpoint') && activeDeviceButton === 'Medium devices') ||
                (!key.includes('SmallBreakpoint') &&
                  !key.includes('MediumBreakpoint') &&
                  activeDeviceButton === 'Large devices')),
          ) && <PropertyButton name="Max-width" />}
        </div>
        <div className="ui-component-settings">
          {theme &&
            props &&
            Object.entries(props).map(([key, value]) => {
              if (
                (key.includes('SmallBreakpoint') && activeDeviceButton === 'Small devices') ||
                (key.includes('MediumBreakpoint') && activeDeviceButton === 'Medium devices') ||
                (!key.includes('SmallBreakpoint') &&
                  !key.includes('MediumBreakpoint') &&
                  activeDeviceButton === 'Large devices')
              ) {
              } else {
                return null;
              }
              if (
                (key.includes('color') && activePropertyButton === 'Color') ||
                (key.includes('background') && activePropertyButton === 'Background') ||
                (key.includes('border') !== key.includes('borderRadius') &&
                  activePropertyButton === 'Border') ||
                (key.includes('borderRadius') && activePropertyButton === 'Border-radius') ||
                (key.includes('Outline') && activePropertyButton === 'Outline') ||
                (key.includes('size') && activePropertyButton === 'Size') ||
                (key.includes('lineHeight') && activePropertyButton === 'Line-height') ||
                (key.includes('weight') && activePropertyButton === 'Weight') ||
                (key.includes('space') && activePropertyButton === 'Space') ||
                (key.includes('gap') && activePropertyButton === 'Gap') ||
                (key.includes('minHeight') && activePropertyButton === 'Min-height') ||
                (key.includes('container') && activePropertyButton === 'Container') ||
                (key.includes('font') && activePropertyButton === 'Font') ||
                (key.includes('maxWidth') && activePropertyButton === 'Max-width')
              ) {
              } else {
                return null;
              }
              const fieldType =
                theme[ComponentName] && theme[ComponentName].values[key]
                  ? theme[ComponentName].values[key].fieldType
                  : null;
              if (fieldType === 'input') {
                return (
                  <div key={key} className="ui-component-settings-item">
                    <label className="ui-label">{theme[ComponentName].values[key].title}</label>
                    <input
                      className="ui-input"
                      type="text"
                      name={key}
                      value={
                        localProps[ComponentName] && localProps[ComponentName][key]
                          ? localProps[ComponentName][key]
                          : localStorage.getItem(ComponentName) &&
                            JSON.parse(localStorage.getItem(ComponentName))[key]
                          ? JSON.parse(localStorage.getItem(ComponentName))[key]
                          : theme[ComponentName]?.values[key]?.default
                      }
                      onChange={(event) => handleChange(event)}
                    />
                  </div>
                );
              } else if (fieldType === 'number') {
                return (
                  <div key={key} className="ui-component-settings-item">
                    <label className="ui-label">{theme[ComponentName].values[key].title}</label>
                    <input
                      className="ui-input"
                      type="number"
                      name={key}
                      value={
                        localProps[ComponentName] && localProps[ComponentName][key]
                          ? parseInt(localProps[ComponentName][key])
                          : localStorage.getItem(ComponentName) &&
                            JSON.parse(localStorage.getItem(ComponentName))[key]
                          ? parseInt(JSON.parse(localStorage.getItem(ComponentName))[key])
                          : theme[ComponentName]?.values[key]?.default
                      }
                      onChange={(event) => handleChange(event)}
                    />
                  </div>
                );
              } else if (fieldType === 'select') {
                return (
                  <div key={key} className="ui-component-settings-item">
                    <label className="ui-label">{theme[ComponentName].values[key].title}</label>
                    <div className="ui-select-wrapper">
                      <div
                        className="ui-select-trigger"
                        onClick={() => {
                          setselectedField(key);
                        }}
                      >
                        <span
                          className={
                            key.includes('color') ||
                            key.includes('background') ||
                            key.includes('borderColor')
                              ? 'ui-select-indicator ui-select-indicator--color'
                              : 'ui-select-indicator'
                          }
                          style={
                            key.includes('color') ||
                            key.includes('background') ||
                            key.includes('borderColor')
                              ? {
                                  backgroundColor: selectedOptions[key]
                                    ? selectedOptions[key].trim()
                                    : theme[ComponentName]?.values[key]?.default,
                                }
                              : {}
                          }
                        ></span>
                        {selectedTitle[key] ||
                          selectedOptions[key] ||
                          theme[ComponentName]?.values[key]?.default ||
                          'Bitte wählen'}
                      </div>
                      <div
                        className={
                          selectedField === key
                            ? 'ui-select-items ui-select-items--active'
                            : 'ui-select-items'
                        }
                      >
                        {getOptions(key).map((option, index) => {
                          const [name, value] = option.split(': ');
                          const title = theme.Typography.values[name]?.title;
                          return (
                            <div className="ui-select-item" key={index}>
                              <span
                                className={
                                  key.includes('color') ||
                                  key.includes('background') ||
                                  key.includes('borderColor')
                                    ? 'ui-select-indicator ui-select-indicator--color'
                                    : 'ui-select-indicator'
                                }
                                style={{
                                  backgroundColor: value.trim(),
                                }}
                              ></span>
                              <div
                                className="ui-select-title"
                                onClick={() => {
                                  const newValue = `var(--${camelToKebab(name)})`;
                                  handleOptionClick(key, newValue);
                                  setSelectedTitle((prevTitles) => ({
                                    ...prevTitles,
                                    [key]: name,
                                  }));
                                  setSelectedOptions((prevOptions) => ({
                                    ...prevOptions,
                                    [key]: newValue,
                                  }));
                                  setselectedField(null);
                                }}
                              >
                                {title ? title : name}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              } else if (fieldType === 'slider') {
                return (
                  <div key={key} className="ui-component-settings-item">
                    <label className="ui-label">{theme[ComponentName].values[key].title}</label>
                    <div className="ui-slider-wrapper">
                      <input
                        className="ui-slider"
                        name={key}
                        type="range"
                        min="0"
                        max={theme[ComponentName]?.values[key]?.max}
                        value={
                          localProps[ComponentName] && localProps[ComponentName][key]
                            ? parseInt(localProps[ComponentName][key])
                            : localStorage.getItem(ComponentName) &&
                              JSON.parse(localStorage.getItem(ComponentName))[key]
                            ? parseInt(JSON.parse(localStorage.getItem(ComponentName))[key])
                            : theme[ComponentName]?.values[key]?.default
                        }
                        onChange={(event) => handleChange(event)}
                      />
                      <div className="ui-slider-value">
                        {localProps[ComponentName] && localProps[ComponentName][key]
                          ? parseInt(localProps[ComponentName][key])
                          : localStorage.getItem(ComponentName) &&
                            JSON.parse(localStorage.getItem(ComponentName))[key]
                          ? parseInt(JSON.parse(localStorage.getItem(ComponentName))[key])
                          : theme[ComponentName]?.values[key]?.default}
                        {theme[ComponentName]?.values[key]?.unit}
                      </div>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
        </div>
      </div>
      <div className="ui-component-markup">
        <SyntaxHighlighter language="htmlbars" style={agate}>
          {markup}
        </SyntaxHighlighter>
      </div>
    </div>
  );
}

export default PreviewComponent;
