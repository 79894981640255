import React, { useRef, useState, useEffect } from 'react';
import { convertPropsToStyle } from '../utils';

const ButtonContent = ({ style, fontText }) => {
  const [styles, setStyles] = useState('');

  const iframeRef = useRef(null);

  useEffect(() => {
    const loadStyles = async (paths) => {
      const fetchPromises = paths.map((path) => fetch(path));
      const styleSheets = await Promise.all(fetchPromises);
      const cssTexts = await Promise.all(styleSheets.map((styleSheet) => styleSheet.text()));
      return cssTexts.join('\n');
    };

    const paths = [
      '../dist/css/theme.css',
      '../dist/css/index.css',
      '../dist/css/uncritical.css',
      '../dist/css/button.css',
    ];
    loadStyles(paths).then(setStyles);
  }, []);

  const [cssStyle, setCssStyle] = useState('');

  useEffect(() => {
    const newStyle = Object.entries(style)
      .map(([prop, value]) => `${prop}: ${value};`)
      .join(' ');
    setCssStyle(newStyle);
    const link = document.createElement('link');
    link.href = `https://fonts.googleapis.com/css2?family=${encodeURIComponent(
      fontText,
    )}:wght@100;200;300;400;500;600;700;800;900`;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  }, [style, fontText]);

  const iframeContent = `
  <link href="https://fonts.googleapis.com/css2?family=${encodeURIComponent(
    fontText,
  )}:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet">
    <style>
      ${styles}
       body {
        font-family: '${fontText}', sans-serif !important;
      }
    </style>
    <button class="button button--small button--primary" style="${cssStyle}">
        Button primary
      </button>
     <button class="button button--small button--secondary" style="${cssStyle}">
        Button primary
      </button>
      <br />
      <br />
    <button class="button button--medium button--primary" style="${cssStyle}">
        Button primary
      </button>
     <button class="button button--medium button--secondary" style="${cssStyle}">
        Button primary
      </button>
        <br />
      <br />
    <button class="button button--large button--primary" style="${cssStyle}">
        Button primary
      </button>
     <button class="button button--large button--secondary" style="${cssStyle}">
        Button primary
      </button>
  `;

  useEffect(() => {
    if (iframeRef.current) {
      const iframe = iframeRef.current;
      const doc = iframe.contentDocument || iframe.contentWindow.document;
      doc.open();
      doc.write(iframeContent);
      doc.close();
      setTimeout(() => {
        iframe.style.height = `${doc.documentElement.scrollHeight}px`;
      }, 100);
    }
  }, [iframeContent]);

  return <iframe title="componentIframe" ref={iframeRef} srcDoc={iframeContent} />;
};

const Button = (props) => {
  let storedProps;
  try {
    storedProps = JSON.parse(localStorage.getItem(`Button`));
  } catch (error) {
    console.error('Error parsing stored props:', error);
  }
  const style = convertPropsToStyle(storedProps || props);

  let typographyProps;
  try {
    typographyProps = JSON.parse(localStorage.getItem(`Typography`));
  } catch (error) {
    console.error('Error parsing stored props:', error);
  }
  const fontText = typographyProps?.fontText || 'Roboto';
  return (
    <div className="ui-component-view">
      <div className="ui-component-inner">
        <div className="ui-pagetitel">Button</div>
        <div className="ui-component-preview">
          <ButtonContent style={style} fontText={fontText} />
        </div>
      </div>
    </div>
  );
};

export const markup = `<button class="button button--small button--primary">
  Button primary
</button>

<button class="button button--medium button--secondary">
  Button medium secondary
</button>

<button class="button button--large button--primary">
  Button large primary
</button>

<button class="button button--large button--primary" disabled>
  Button disabled
</button>
`;

export default Button;
