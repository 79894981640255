import React, { useRef, useState, useEffect } from 'react';
import { convertPropsToStyle, getThemeVariables } from '../utils';

const TextContent = ({ style, fontText }) => {
  const [styles, setStyles] = useState('');
  const [scripts, setScripts] = useState('');

  const iframeRef = useRef(null);

  useEffect(() => {
    const loadStyles = async (paths) => {
      const fetchPromises = paths.map((path) => fetch(path));
      const styleSheets = await Promise.all(fetchPromises);
      const cssTexts = await Promise.all(styleSheets.map((styleSheet) => styleSheet.text()));
      return cssTexts.join('\n');
    };

    const paths = [
      '../dist/css/theme.css',
      '../dist/css/index.css',
      '../dist/css/uncritical.css',
      '../dist/css/button.css',
      '../dist/css/text.css',
    ];
    loadStyles(paths).then(setStyles);
  }, []);

  const [cssStyle, setCssStyle] = useState('');

  useEffect(() => {
    const loadScripts = async (paths) => {
      const fetchPromises = paths.map((path) => fetch(path));
      const scripts = await Promise.all(fetchPromises);
      const jsTexts = await Promise.all(scripts.map((script) => script.text()));
      return jsTexts.join('\n');
    };

    const paths = ['../dist/js/index.js'];
    loadScripts(paths).then(setScripts);
  }, []);

  useEffect(() => {
    const newStyle = Object.entries(style)
      .map(([prop, value]) => `${prop}: ${value};`)
      .join(' ');
    setCssStyle(newStyle);
    const link = document.createElement('link');
    link.href = `https://fonts.googleapis.com/css2?family=${encodeURIComponent(
      fontText,
    )}:wght@100;200;300;400;500;600;700;800;900`;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  }, [style, fontText]);

  const iframeContent = `
   <style>
      ${styles}
    </style>
     <script>
    ${scripts}
  </script>
   <div class="text" style="${cssStyle}">
   <div class="text-inner">
      <div class="text-eyebrow">
          <span>Dies ist eine Überschrift</span>
      </div>
      <div class="text-headline">
        <h1>Dies ist eine Überschrift</h1>
      </div>
      <div class="text-section">
        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
      </div>
     <div class="text-button button-group">
        <a href="#" class="button button--primary button--medium">CTA Button</a>
        <a href="#" class="button button--secondary button--medium">CTA Button</a>
      </div>
  </div>
</div>
  `;

  useEffect(() => {
    if (iframeRef.current) {
      const iframe = iframeRef.current;
      const doc = iframe.contentDocument || iframe.contentWindow.document;
      doc.open();
      doc.write(iframeContent);
      doc.close();
      getThemeVariables(doc.documentElement);
      setTimeout(() => {
        iframe.style.height = `${doc.documentElement.scrollHeight}px`;
      }, 100);
    }
  }, [iframeContent]);

  return <iframe title="componentIframe" ref={iframeRef} srcDoc={iframeContent} />;
};

const Text = (props) => {
  let storedProps;
  try {
    storedProps = JSON.parse(localStorage.getItem(`Text`));
  } catch (error) {
    console.error('Error parsing stored props:', error);
  }
  const style = convertPropsToStyle(storedProps || props);
  return (
    <div className="ui-component-view">
      <div className="ui-component-inner">
        <div className="ui-pagetitel">Text</div>
        <div className="ui-component-preview">
          <TextContent style={style} />
        </div>
      </div>
    </div>
  );
};

export const markup = ` <div class="text">
   <div class="text-inner">
      <div class="text-eyebrow">
          <span>Dies ist eine Überschrift</span>
      </div>
      <div class="text-headline">
        <h1>Dies ist eine Überschrift</h1>
      </div>
      <div class="text-section">
        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
      </div>
     <div class="text-button button-group">
        <a href="#" class="button button--primary button--medium">CTA Button</a>
        <a href="#" class="button button--secondary button--medium">CTA Button</a>
      </div>
  </div>
</div>
`;

export default Text;
