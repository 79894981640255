import React from 'react';

const start = () => {
  return (
    <div className="start-content">
      <h1 className="start-title">Welcome to the prototype of themex 🚀</h1>
      <p>
        As a front-end developer with over 15 years of experience in implementing websites, I have
        frequently experimented with new CSS frameworks. Frameworks like Bootstrap and Tailwind
        offer well-thought-out concepts that enable efficient and time-saving implementation of CSS
        declarations within the DOM. However, they haven't been efficient enough for me, especially
        in website development. Most websites today tend to follow a similar pattern and are not
        inherently complex. Typically, they involve fonts, colors, and other CSS properties that are
        defined globally and later passed on to child elements. The real challenge arises,
        especially when varying a web component, as it becomes confusing and difficult to replicate
        in other projects. The complexity further increases when a CMS is involved, leading to more
        intricate code in the DOM. This is why I created Themex. It is primarily designed to ensure
        reproduction, clarity, and intuitiveness in all kinds of website projects. Themex is built
        on <strong className="highlight">Vanilla JavaScript, CSS, and HTML.</strong>
      </p>

      <h2>You can edit all the visual parts of a website within this application.</h2>

      <p>
        <strong className="highlight">Themex is not another CSS framework!</strong> It's much more.
        Themex aims to prevent a blank screen in your IDE and serves as a solid foundation for
        further development. It {''}
        <strong className="highlight">
          provides a DOM and CSS variables structure as well as CSS Declarations.
        </strong>
        Comprehensive documentation is not yet available, but I invite you to try it out and welcome
        your suggestions. You can define your theme in the browser and then download it, along with
        all the required assets, by clicking <strong className="highlight">'Generate Theme'</strong>
        . Experiment with it and consider if it could be a practical solution for your projects.
      </p>

      <p>
        What do you think? <br />
        Best regards <br />
        Karsten 😊
      </p>
    </div>
  );
};

export default start;
