import React, { useRef, useState, useEffect } from 'react';
import { convertPropsToStyle, getThemeVariables } from '../utils';

const CardContent = ({ style, fontText }) => {
  const [styles, setStyles] = useState('');
  const [scripts, setScripts] = useState('');

  const iframeRef = useRef(null);

  useEffect(() => {
    const loadStyles = async (paths) => {
      const fetchPromises = paths.map((path) => fetch(path));
      const styleSheets = await Promise.all(fetchPromises);
      const cssTexts = await Promise.all(styleSheets.map((styleSheet) => styleSheet.text()));
      return cssTexts.join('\n');
    };

    const paths = [
      '../dist/css/theme.css',
      '../dist/css/index.css',
      '../dist/css/uncritical.css',
      '../dist/css/card.css',
    ];
    loadStyles(paths).then(setStyles);
  }, []);

  const [cssStyle, setCssStyle] = useState('');

  useEffect(() => {
    const loadScripts = async (paths) => {
      const fetchPromises = paths.map((path) => fetch(path));
      const scripts = await Promise.all(fetchPromises);
      const jsTexts = await Promise.all(scripts.map((script) => script.text()));
      return jsTexts.join('\n');
    };

    const paths = ['../dist/js/index.js'];
    loadScripts(paths).then(setScripts);
  }, []);

  useEffect(() => {
    const newStyle = Object.entries(style)
      .map(([prop, value]) => `${prop}: ${value};`)
      .join(' ');
    setCssStyle(newStyle);
    const link = document.createElement('link');
    link.href = `https://fonts.googleapis.com/css2?family=${encodeURIComponent(
      fontText,
    )}:wght@100;200;300;400;500;600;700;800;900`;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  }, [style, fontText]);

  const iframeContent = `
   <style>
      ${styles}
    </style>
     <script>
    ${scripts}
  </script>
    <style>
      ${styles}
    </style>
     <script>
    ${scripts}
  </script>
   <div class="card" style="${cssStyle}">
  <div class="card-inner">
  <div class="card-grid">
    <div class="card-item">
      <div class="card-icon">
        <i class="icon icon--md icon-page"></i>
      </div>
      <div class="card-text">
        <div class="card-eyebrow"><span>Medium Eyebrow</span></div>
        <div class="card-headline">
          <h1>Card headline</h1>
        </div>
        <div class="card-text">
          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
        </div>
        <div class="card-button">
          <a href="#" class="card-button-primary">Link öffnen</a>
        </div>
      </div>
    </div>
    <div class="card-item">
      <div class="card-icon">
        <i class="icon icon--md icon-page"></i>
      </div>
      <div class="card-text">
        <div class="card-eyebrow"><span>Medium Eyebrow</span></div>
        <div class="card-headline">
          <h1>Card headline</h1>
        </div>
        <div class="card-text">
          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
        </div>
        <div class="card-button">
          <a href="#" class="card-button-primary">Link öffnen</a>
        </div>
      </div>
    </div>
    <div class="card-item">
      <div class="card-icon">
        <i class="icon icon--md icon-page"></i>
      </div>
      <div class="card-text">
        <div class="card-eyebrow"><span>Medium Eyebrow</span></div>
        <div class="card-headline">
          <h1>Card headline</h1>
        </div>
        <div class="card-text">
          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
        </div>
        <div class="card-button">
          <a href="#" class="card-button-primary">Link öffnen</a>
        </div>
      </div>
    </div>
    </div>
  </div>
</div>
  `;

  useEffect(() => {
    if (iframeRef.current) {
      const iframe = iframeRef.current;
      const doc = iframe.contentDocument || iframe.contentWindow.document;
      doc.open();
      doc.write(iframeContent);
      doc.close();
      getThemeVariables(doc.documentElement);
      setTimeout(() => {
        iframe.style.height = `${doc.documentElement.scrollHeight}px`;
      }, 100);
    }
  }, [iframeContent]);

  return <iframe title="componentIframe" ref={iframeRef} srcDoc={iframeContent} />;
};

const Cards = (props) => {
  let storedProps;
  try {
    storedProps = JSON.parse(localStorage.getItem(`Cards`));
  } catch (error) {
    console.error('Error parsing stored props:', error);
  }
  const style = convertPropsToStyle(storedProps || props);
  return (
    <div className="ui-component-view">
      <div className="ui-component-inner">
        <div className="ui-pagetitel">Cards</div>
        <div className="ui-component-preview">
          <CardContent style={style} />
        </div>
      </div>
    </div>
  );
};

export const markup = ` <div class="card">
  <div class="card-inner">
  <div class="card-grid">
    <div class="card-item">
      <div class="card-icon">
        <i class="icon icon--md icon-page"></i>
      </div>
      <div class="card-text">
        <div class="card-eyebrow"><span>Medium Eyebrow</span></div>
        <div class="card-headline">
          <h1>Card headline</h1>
        </div>
        <div class="card-text">
          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
        </div>
        <div class="card-button">
          <a href="#" class="card-button-primary">Link öffnen</a>
        </div>
      </div>
    </div>
    <div class="card-item">
      <div class="card-icon">
        <i class="icon icon--md icon-page"></i>
      </div>
      <div class="card-text">
        <div class="card-eyebrow"><span>Medium Eyebrow</span></div>
        <div class="card-headline">
          <h1>Card headline</h1>
        </div>
        <div class="card-text">
          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
        </div>
        <div class="card-button">
          <a href="#" class="card-button-primary">Link öffnen</a>
        </div>
      </div>
    </div>
    <div class="card-item">
      <div class="card-icon">
        <i class="icon icon--md icon-page"></i>
      </div>
      <div class="card-text">
        <div class="card-eyebrow"><span>Medium Eyebrow</span></div>
        <div class="card-headline">
          <h1>Card headline</h1>
        </div>
        <div class="card-text">
          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
        </div>
        <div class="card-button">
          <a href="#" class="card-button-primary">Link öffnen</a>
        </div>
      </div>
    </div>
    </div>
  </div>
</div>
`;

export default Cards;
