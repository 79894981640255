import React, { useRef, useState, useEffect } from 'react';
import { convertPropsToStyle, getThemeVariables } from '../utils';

const FormContent = ({ style, fontText }) => {
  const [styles, setStyles] = useState('');
  const [scripts, setScripts] = useState('');

  const iframeRef = useRef(null);

  useEffect(() => {
    const loadStyles = async (paths) => {
      const fetchPromises = paths.map((path) => fetch(path));
      const styleSheets = await Promise.all(fetchPromises);
      const cssTexts = await Promise.all(styleSheets.map((styleSheet) => styleSheet.text()));
      return cssTexts.join('\n');
    };

    const paths = [
      '../dist/css/theme.css',
      '../dist/css/index.css',
      '../dist/css/uncritical.css',
      '../dist/css/button.css',
      '../dist/css/form.css',
    ];
    loadStyles(paths).then(setStyles);
  }, []);

  const [cssStyle, setCssStyle] = useState('');

  useEffect(() => {
    const loadScripts = async (paths) => {
      const fetchPromises = paths.map((path) => fetch(path));
      const scripts = await Promise.all(fetchPromises);
      const jsTexts = await Promise.all(scripts.map((script) => script.text()));
      return jsTexts.join('\n');
    };

    const paths = ['../dist/js/index.js'];
    loadScripts(paths).then(setScripts);
  }, []);

  useEffect(() => {
    const newStyle = Object.entries(style)
      .map(([prop, value]) => `${prop}: ${value};`)
      .join(' ');
    setCssStyle(newStyle);
    const link = document.createElement('link');
    link.href = `https://fonts.googleapis.com/css2?family=${encodeURIComponent(
      fontText,
    )}:wght@100;200;300;400;500;600;700;800;900`;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  }, [style, fontText]);

  const iframeContent = `
   <style>
      ${styles}
    </style>
     <script>
    ${scripts}
  </script>
  <div style="padding: 5px;">
   <form class="form" style="${cssStyle}">
  <div class="form-element">
    <div class="form-element-label">
      <label for="text">Text input *</label>
      <div class="form-element-character-counter"><span class="form-element-character-counter-current">0</span> / 20 Zeichen</div>
    </div>
    <input name="text" class="input form-element-field" placeholder="Name" type="text" maxLength="20" required="true">
    <div class="form-element-message">
      <div class="form-element-message-icon"></div>Dies ist ein Pflichtfeld
    </div>
    <div class="form-element-info">
      Hilfestellung für die UserInnen
    </div>
  </div>
  <div class="form-element">
    <div class="form-element-label">
      <label for="text">Text input</label>
      <div class="form-element-character-counter"><span class="form-element-character-counter-current">0</span> / 20 Zeichen</div>
    </div>
    <input name="text" class="input form-element-field" placeholder="Name" type="text" maxLength="20">
    <div class="form-element-message">
      <div class="form-element-message-icon"></div>Dies ist ein Pflichtfeld
    </div>
  </div>
  <div class="form-element">
    <div class="form-element-label">
      <label for="text">Textarea</label>
      <div class="form-element-character-counter"><span class="form-element-character-counter-current">0</span> / 300 Zeichen</div>
    </div>
    <textarea name="textarea" class="form-element-field textarea character-counter" placeholder="Text" maxLength="300"></textarea>
    <div class="form-element-message">
      <div class="form-element-message-icon"></div>Dies ist ein Pflichtfeld
    </div>
  </div>
   <div class="form-element datepicker">
   <div class="form-element-label">
      <label>Datepicker</label>
    </div>
    <div class="form-element-input">
    <div class="form-element-icon"><i class="icon icon--sm icon-calendar icon--black"></i></div>
    <input type="text" class="datepicker-input form-element-field input" placeholder="XX.XX.XXXX">
  </div>
    <div class="datepicker-calendar" data-language="de-DE">
     <div class="datepicker-calendar-navigation">
      <div class="datepicker-month">
        <select class="datepicker-month-dropdown"></select>
      </div>
      <button type="button" class="datepicker-button datepicker-button--prev">Zurück</button>
      <button type="button" class="datepicker-button datepicker-button--next">Weiter</button>
     </div>
      <div class="datepicker-calendar-header">
        <div class="datepicker-calendar-header-days">
          <div class="datepicker-calendar-header-day">
            M
          </div>
          <div class="datepicker-calendar-header-day">
            D
          </div>
          <div class="datepicker-calendar-header-day">
            M
          </div>
          <div class="datepicker-calendar-header-day">
            D
          </div>
          <div class="datepicker-calendar-header-day">
            F
          </div>
          <div class="datepicker-calendar-header-day">
            S
          </div>
          <div class="datepicker-calendar-header-day">
            S
          </div>
        </div>
      </div>
      <div class="datepicker-calendar-container">
        <div class="datepicker-calendar-month"></div>
      </div>
    </div>
  </div>
  <div class="form-element">
    <div class="form-element-label">
      <fieldset>
        <legend>Checkbox list *</legend>
      </fieldset>
    </div>
    <div class="form-element-items">
      <div class="checkbox">
        <label>
          <input class="form-element-field" type="checkbox" required="true">
          <span class="checkbox-icon"></span>
          <span class="checkbox-label">Checkbox select</span>
        </label>
      </div>
      <div class="checkbox">
        <label>
          <input class="form-element-field" type="checkbox">
          <span class="checkbox-icon"></span>
          <span class="checkbox-label">Checkbox select</span>
        </label>
      </div>
      <div class="checkbox">
        <label>
          <input class="form-element-field" type="checkbox">
          <span class="checkbox-icon"></span>
          <span class="checkbox-label">Checkbox select</span>
        </label>
      </div>
    </div>
    <div class="form-element-message">
      <div class="form-element-message-icon"></div>Please choose at least 1 element
    </div>
  </div>
  <div class="form-element">
    <div class="form-element-label">
      <fieldset>
        <legend>Radio:</legend>
      </fieldset>
    </div>
    <div class="form-element-items">
      <div class="radio">
        <label>
          <input type="radio" class="form-element-field" name="radio">
          <span class="radio-icon"></span>
          <span class="radio-label">Radio select</span>
        </label>
      </div>
      <div class="radio">
        <label>
          <input type="radio" class="form-element-field" name="radio">
          <span class="radio-icon"></span>
          <span class="radio-label">Radio select</span>
        </label>
      </div>
      <div class="radio">
        <label>
          <input type="radio" class="form-element-field" name="radio">
          <span class="radio-icon"></span>
          <span class="radio-label">Radio select</span>
        </label>
      </div>
      <div class="radio">
        <label>
          <input type="radio" class="form-element-field" name="radio">
          <span class="radio-icon"></span>
          <span class="radio-label">Radio select</span>
        </label>
      </div>
    </div>
    <div class="form-element-message">
      <div class="form-element-message-icon"></div>Please choose at least 1 element
    </div>
  </div>
  <div class="form-element">
    <div class="select">
      <div class="select-trigger">Auswahl</div>
      <div class="select-options" id="select-options-1">
        <div class="select-option" data-title="Auswahl 1" data-value="auswahl1">Auswahl 1</div>
        <div class="select-option" data-title="Auswahl 2" data-value="auswahl2">Auswahl 2</div>
        <div class="select-option" data-title="Auswahl 3" data-value="auswahl3">Auswahl 3</div>
        <div class="select-option" data-title="Auswahl 4" data-value="auswahl4">Auswahl 4</div>
        <div class="select-option" data-title="Auswahl 5" data-value="auswahl5">Auswahl 5</div>
      </div>
      <select class="form-element-field">
        <option value="Auswahl 1">Auswahl 1</option>
        <option value="Auswahl 2">Auswahl 2</option>
        <option value="Auswahl 3">Auswahl 3</option>
        <option value="Auswahl 4">Auswahl 4</option>
        <option value="Auswahl 5">Auswahl 5</option>
      </select>
    </div>
  </div>
  <div class="form-element">
    <div class="select">
      <div class="select-trigger">Auswahl</div>
      <div class="select-options" id="select-options-2">
        <div class="select-option" data-title="Auswahl 1" data-value="auswahl1">Auswahl 1</div>
        <div class="select-option" data-title="Auswahl 2" data-value="auswahl2">Auswahl 2</div>
        <div class="select-option" data-title="Auswahl 3" data-value="auswahl3">Auswahl 3</div>
        <div class="select-option" data-title="Auswahl 4" data-value="auswahl4">Auswahl 4</div>
        <div class="select-option" data-title="Auswahl 5" data-value="auswahl5">Auswahl 5</div>
      </div>
      <select class="form-element-field">
        <option value="Auswahl 1">Auswahl 1</option>
        <option value="Auswahl 2">Auswahl 2</option>
        <option value="Auswahl 3">Auswahl 3</option>
        <option value="Auswahl 4">Auswahl 4</option>
        <option value="Auswahl 5">Auswahl 5</option>
      </select>
    </div>
  </div>
  <div class="form-element">
    <div class="select">
      <div class="select-trigger">Auswahl</div>
      <div class="select-options" id="select-options-3">
        <div class="select-option" data-title="Auswahl 1" data-value="auswahl1">Auswahl 1</div>
        <div class="select-option" data-title="Auswahl 2" data-value="auswahl2">Auswahl 2</div>
        <div class="select-option" data-title="Auswahl 3" data-value="auswahl3">Auswahl 3</div>
        <div class="select-option" data-title="Auswahl 4" data-value="auswahl4">Auswahl 4</div>
        <div class="select-option" data-title="Auswahl 5" data-value="auswahl5">Auswahl 5</div>
      </div>
      <select class="form-element-field">
        <option value="Auswahl 1">Auswahl 1</option>
        <option value="Auswahl 2">Auswahl 2</option>
        <option value="Auswahl 3">Auswahl 3</option>
        <option value="Auswahl 4">Auswahl 4</option>
        <option value="Auswahl 5">Auswahl 5</option>
      </select>
    </div>
  </div>
  <div class="form-element">
    <div class="form-element-label">
      <fieldset>
        <legend>Checkbox *</legend>
      </fieldset>
    </div>
    <div class="form-element-items">
      <div class="checkbox">
        <label>
          <input class="form-element-field" type="checkbox" required="true">
          <span class="checkbox-icon"></span>
          <span class="checkbox-label">Checkbox select</span>
        </label>
      </div>
    </div>
    <div class="form-element-message">
      <div class="form-element-message-icon"></div>Diese ist ein Pflichtfeld
    </div>
  </div>
  <div class="form-submit">
    <button class="button button--primary button--medium form-action-submit" type="submit" disabled="">Submit form</button>
  </div>
</form>
</div>
  `;

  useEffect(() => {
    if (iframeRef.current) {
      const iframe = iframeRef.current;
      const doc = iframe.contentDocument || iframe.contentWindow.document;
      doc.open();
      doc.write(iframeContent);
      doc.close();
      getThemeVariables(doc.documentElement);
      setTimeout(() => {
        iframe.style.height = `${doc.documentElement.scrollHeight}px`;
      }, 30);
    }
  }, [iframeContent]);

  return <iframe title="componentIframe" ref={iframeRef} srcDoc={iframeContent} />;
};

const Form = (props) => {
  let storedProps;
  try {
    storedProps = JSON.parse(localStorage.getItem(`Form`));
  } catch (error) {
    console.error('Error parsing stored props:', error);
  }
  const style = convertPropsToStyle(storedProps || props);
  return (
    <div className="ui-component-view">
      <div className="ui-component-inner">
        <div className="ui-pagetitel">Form</div>
        <div className="ui-component-preview">
          <FormContent style={style} />
        </div>
      </div>
    </div>
  );
};

export const markup = `<div class="form-element">
    <div class="form-element-label">
      <label for="text">Text input *</label>
      <div class="form-element-character-counter"><span class="form-element-character-counter-current">0</span> / 20 Zeichen</div>
    </div>
    <input name="text" class="input form-element-field" placeholder="Name" type="text" maxlength="20" required="true">
    <div class="form-element-message">
      <div class="form-element-message-icon"></div>Dies ist ein Pflichtfeld
    </div>
    <div class="form-element-info">
      Hilfestellung für die UserInnen
    </div>
  </div>
`;

export default Form;
