import JSZip from 'jszip';

export const camelToKebab = (str) => {
  return str.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
};

export const convertPropsToStyle = (props) => {
  return Object.entries(props).reduce((acc, [key, value]) => {
    if (value !== undefined) {
      acc[`--${camelToKebab(key)}`] = `${value}`;
    }
    return acc;
  }, {});
};

export function updateCssVariables(value, variableName) {
  if (typeof value === 'object' && value !== null) {
    for (const [subKey, subValue] of Object.entries(value)) {
      const kebabSubKey = camelToKebab(subKey);
      if (typeof subValue === 'object' && subValue !== null) {
        updateCssVariables(subValue, `${variableName ? variableName + '-' : ''}${kebabSubKey}`);
      } else {
        document.documentElement.style.setProperty(
          `--${variableName ? variableName + '-' : ''}${kebabSubKey}`,
          subValue,
        );
      }
    }
  } else {
    document.documentElement.style.setProperty(`--${camelToKebab(variableName)}`, value);
  }
}

export const generateColorCss = (props) => {
  let css = ':root {\n';

  for (const [key, value] of Object.entries(props)) {
    if (typeof value === 'object' && value !== null) {
      for (const [subKey, subValue] of Object.entries(value)) {
        css += `  --${subKey}: ${subValue};\n`;
        document.documentElement.style.setProperty(`--${subKey}`, subValue);
      }
    } else {
      css += `  --${camelToKebab(key)}: ${value};\n`;
      document.documentElement.style.setProperty(`--${camelToKebab(key)}`, value);
    }
  }

  css += '}';
  return css;
};
export const generateComponentsCss = (props) => {
  let css = ':root {\n';

  for (const [key, value] of Object.entries(props)) {
    if (typeof value === 'object' && value !== null) {
      for (const [subKey, subValue] of Object.entries(value)) {
        css += `  --${camelToKebab(subKey)}: ${subValue};\n`;
        document.documentElement.style.setProperty(`--${camelToKebab(subKey)}`, `${subValue}`);
      }
    } else {
      css += `  --${camelToKebab(key)}: ${value};\n`;
      document.documentElement.style.setProperty(`--${camelToKebab(key)}`, `${value}`, `${value}`);
    }
  }

  css += '}';
  return css;
};

export const getThemeVariables = (element) => {
  const themeColors = JSON.parse(localStorage.getItem('themeColors'));
  const containerValues = JSON.parse(localStorage.getItem('Container'));
  const textValues = JSON.parse(localStorage.getItem('Typography'));
  for (const [key, value] of Object.entries(themeColors)) {
    if (typeof value === 'object' && value !== null) {
      for (const [subKey, subValue] of Object.entries(value)) {
        if (key === 'accent' || key === 'neutral' || key === 'red' || key === 'green') {
          element.style.setProperty(`--${camelToKebab(subKey)}`, subValue);
        } else {
          element.style.setProperty(`--${camelToKebab(key)}-${camelToKebab(subKey)}`, subValue);
        }
      }
    } else {
      element.style.setProperty(`--${camelToKebab(key)}`, value);
    }
  }

  for (const [key, value] of Object.entries(containerValues)) {
    element.style.setProperty(`--${camelToKebab(key)}`, value);
  }

  for (const [key, value] of Object.entries(textValues)) {
    element.style.setProperty(`--${camelToKebab(key)}`, value);
  }

  const keys = Object.keys(localStorage);
  for (const key of keys) {
    if (key === 'themeColors') continue;
    const value = localStorage.getItem(key);
    if (value.startsWith('{') && value.endsWith('}')) {
      const parsedValue = JSON.parse(value);
      for (const [subKey, subValue] of Object.entries(parsedValue)) {
        element.style.setProperty(`--${camelToKebab(subKey)}`, subValue);
      }
    } else {
      element.style.setProperty(`--${camelToKebab(key)}`, value);
    }
  }
};

export const generateTheme = async () => {
  const zip = new JSZip();

  let css = ':root {\n';

  const themeColors = JSON.parse(localStorage.getItem('themeColors'));
  for (const [key, value] of Object.entries(themeColors)) {
    if (typeof value === 'object' && value !== null) {
      for (const [subKey, subValue] of Object.entries(value)) {
        let finalValue = subValue;
        if (subKey.includes('size') && typeof subValue === 'string' && subValue.endsWith('px')) {
          finalValue = `${parseInt(subValue) / 16}rem`;
        }
        if (key === 'accent' || key === 'neutral' || key === 'red' || key === 'green') {
          css += `  --${camelToKebab(subKey)}: ${finalValue};\n`;
        } else {
          css += `  --${camelToKebab(key)}-${camelToKebab(subKey)}: ${finalValue};\n`;
        }
      }
    } else {
      let finalValue = value;
      if (
        (key.includes('size') && typeof value === 'string' && value.endsWith('px')) ||
        (key.includes('space') && typeof value === 'string' && value.endsWith('px'))
      ) {
        finalValue = `${parseInt(value) / 16}rem`;
      }
      css += `  --${camelToKebab(key)}: ${finalValue};\n`;
    }
  }

  const keys = Object.keys(localStorage);
  for (const key of keys) {
    if (key === 'themeColors') continue;
    const value = localStorage.getItem(key);
    if (value.startsWith('{') && value.endsWith('}')) {
      const parsedValue = JSON.parse(value);
      for (const [subKey, subValue] of Object.entries(parsedValue)) {
        let finalValue = subValue;
        if (
          (subKey.includes('size') && typeof subValue === 'string' && subValue.endsWith('px')) ||
          (subKey.includes('space') && typeof subValue === 'string' && subValue.endsWith('px'))
        ) {
          finalValue = `${parseInt(subValue) / 16}rem`;
        }
        css += `  --${camelToKebab(subKey)}: ${finalValue};\n`;
      }
    } else {
      let finalValue = value;
      if (
        (key.includes('size') && typeof value === 'string' && value.endsWith('px')) ||
        (key.includes('space') && typeof value === 'string' && value.endsWith('px'))
      ) {
        finalValue = `${parseInt(value) / 16}rem`;
      }
      css += `  --${camelToKebab(key)}: ${finalValue};\n`;
    }
  }

  css += '}';

  zip.file('theme.css', css);

  const urls = [
    'https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css',
    'https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css',
  ];

  for (const url of urls) {
    const response = await fetch(url);
    const data = await response.blob();
    zip.file(url.split('/').pop(), data);
  }

  const content = await zip.generateAsync({ type: 'blob' });

  const url = URL.createObjectURL(content);

  const a = document.createElement('a');
  a.href = url;
  a.download = 'theme.zip';

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
