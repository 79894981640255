import React, { useState, useEffect } from 'react';
import { useTheme, ThemeProvider } from './ThemeContext';
import Start from './components/Start';
import Colors from './components/Colors';
import Typography, { markup as typographyMarkup } from './components/Typography';
import Container, { markup as containerMarkup } from './components/Container';
import Accordion, { markup as accordionMarkup } from './components/Accordion';
import Button, { markup as buttonMarkup } from './components/Button';
import Cards, { markup as cardMarkup } from './components/Cards';
import Hero, { markup as heroMarkup } from './components/Hero';
import Modal, { markup as modalMarkup } from './components/Modal';
import Text, { markup as textMarkup } from './components/Text';
import Form, { markup as formMarkup } from './components/Form';
import PreviewComponent from './PreviewComponent';
import { generateTheme, generateColorCss } from './utils';
import defaultValues from './theme.json';
import brand from './img/brand.svg';
import './scss/App.scss';

const App = () => {
  return (
    <ThemeProvider>
      <AppContent />
    </ThemeProvider>
  );
};

const AppContent = () => {
  const [selectedComponent, setSelectedComponent] = useState('Start');
  const renderComponent = () => {
    switch (selectedComponent) {
      case 'Start':
        return <Start />;
      case 'Colors':
        return <Colors />;
      case 'Typography':
        return (
          <PreviewComponent
            Component={Typography}
            ComponentName="Typography"
            Id="TypographyKey"
            markup={typographyMarkup}
          />
        );
      case 'Container':
        return (
          <PreviewComponent
            Component={Container}
            ComponentName="Container"
            Id="ContainerKey"
            markup={containerMarkup}
          />
        );
      case 'Button':
        return (
          <PreviewComponent
            Component={Button}
            ComponentName="Button"
            Id="ButtonKey"
            markup={buttonMarkup}
          />
        );
      case 'Accordion':
        return (
          <PreviewComponent
            Component={Accordion}
            ComponentName="Accordion"
            Id="AccordionKey"
            markup={accordionMarkup}
          />
        );
      case 'Cards':
        return (
          <PreviewComponent
            Component={Cards}
            ComponentName="Cards"
            Id="CardsKey"
            markup={cardMarkup}
          />
        );
      case 'Hero':
        return (
          <PreviewComponent
            Component={Hero}
            ComponentName="Hero"
            Id="HeroKey"
            markup={heroMarkup}
          />
        );
      case 'Modal':
        return (
          <PreviewComponent
            Component={Modal}
            ComponentName="Modal"
            Id="ModalKey"
            markup={modalMarkup}
          />
        );
      case 'Form':
        return (
          <PreviewComponent
            Component={Form}
            ComponentName="Form"
            Id="FormKey"
            markup={formMarkup}
          />
        );
      case 'Text':
        return (
          <PreviewComponent
            Component={Text}
            ComponentName="Text"
            Id="TextKey"
            markup={textMarkup}
          />
        );
      default:
        return null;
    }
  };

  const { theme = {} } = useTheme();

  // generate initial color css to make sure that the components are styled correctly
  useEffect(() => {
    const initialComponents = [
      'Typography',
      'Container',
      'Button',
      'Accordion',
      'Cards',
      'Hero',
      'Form',
      'Modal',
      'Text',
    ];
    const storedColors =
      JSON.parse(localStorage.getItem('themeColors')) || defaultValues.theme.Colors;
    if (storedColors) {
      generateColorCss(storedColors);
    } else if (theme.colorProps) {
      generateColorCss(theme.colorProps);
    }
    initialComponents.forEach((component) => {
      if (JSON.parse(localStorage.getItem(component)) === null) {
        const componentDefaults = Object.entries(defaultValues.theme[component].values).reduce(
          (acc, [key, value]) => {
            acc[key] = value.default;
            return acc;
          },
          {},
        );
        localStorage.setItem(component, JSON.stringify(componentDefaults));
      }
    });
    if (JSON.parse(localStorage.getItem('themeColors') === null)) {
      localStorage.setItem('themeColors', JSON.stringify(defaultValues.theme.Colors));
    }
  }, [theme]);

  return (
    <div className="app">
      <div className="ui-main">
        <div className="ui-sidebar">
          <div className="ui-header">
            <div className="ui-brand">
              <img src={brand} alt="brand" />
            </div>
          </div>
          <div className="sidebar-navigation">
            <div className="sidebar-subtitle">Foundations</div>
            <button
              className={`ui-navigation-button ${selectedComponent === 'Colors' ? 'active' : ''}`}
              onClick={() => setSelectedComponent('Colors')}
            >
              Colors
            </button>
            <button
              className={`ui-navigation-button ${
                selectedComponent === 'Typography' ? 'active' : ''
              }`}
              onClick={() => setSelectedComponent('Typography')}
            >
              Typography
            </button>
            <button
              className={`ui-navigation-button ${
                selectedComponent === 'Container' ? 'active' : ''
              }`}
              onClick={() => setSelectedComponent('Container')}
            >
              Container
            </button>
            <div className="sidebar-subtitle">Shared components</div>
            <button
              className={`ui-navigation-button ${selectedComponent === 'Button' ? 'active' : ''}`}
              onClick={() => setSelectedComponent('Button')}
            >
              Button
            </button>
            <div className="sidebar-subtitle">Components</div>
            <button
              className={`ui-navigation-button ${
                selectedComponent === 'Accordion' ? 'active' : ''
              }`}
              onClick={() => setSelectedComponent('Accordion')}
            >
              Accordion
            </button>
            <button
              className={`ui-navigation-button ${selectedComponent === 'Cards' ? 'active' : ''}`}
              onClick={() => setSelectedComponent('Cards')}
            >
              Cards
            </button>
            <button
              className={`ui-navigation-button ${selectedComponent === 'Hero' ? 'active' : ''}`}
              onClick={() => setSelectedComponent('Hero')}
            >
              Hero
            </button>
            <button
              className={`ui-navigation-button ${selectedComponent === 'Text' ? 'active' : ''}`}
              onClick={() => setSelectedComponent('Text')}
            >
              Text section
            </button>
            <button
              className={`ui-navigation-button ${selectedComponent === 'Modal' ? 'active' : ''}`}
              onClick={() => setSelectedComponent('Modal')}
            >
              Modal
            </button>
            <button
              className={`ui-navigation-button ${selectedComponent === 'Form' ? 'active' : ''}`}
              onClick={() => setSelectedComponent('Form')}
            >
              Forms
            </button>
          </div>
          <div className="sidebar-actions">
            <button onClick={() => generateTheme()} className="ui-action-button">
              Generate Theme
            </button>
          </div>
        </div>
        <div className="ui-component">{renderComponent()}</div>
      </div>
    </div>
  );
};

export default App;
