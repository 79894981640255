import React, { useState, useEffect } from 'react';
import { camelToKebab } from '../utils';
import { useTheme } from '../ThemeContext';
import defaultValues from '../theme.json';

function Colors({ Colors, fontText }) {
  const { theme = {}, setTheme } = useTheme();
  const [colors, setColors] = useState(theme.Colors || {});
  const [colorProps, setColorProps] = useState(theme.colorProps || {});

  useEffect(() => {
    const storedColors =
      JSON.parse(localStorage.getItem('themeColors')) || defaultValues.theme.Colors;
    if (storedColors) {
      setColors(storedColors);
      setColorProps(storedColors);
    } else {
      setColors(theme.Colors || {});
      setColorProps(theme.colorProps || {});
    }
  }, [theme]);

  const handleChange = (event, colorKey, shadeKey = null) => {
    const { value } = event.target;

    const storedColors = JSON.parse(localStorage.getItem('themeColors')) || colors;

    let updatedColors;
    if (shadeKey) {
      updatedColors = {
        ...storedColors,
        [colorKey]: { ...storedColors[colorKey], [shadeKey]: value },
      };
    } else {
      updatedColors = { ...storedColors, [colorKey]: value };
    }

    localStorage.setItem('themeColors', JSON.stringify(updatedColors));

    setColorProps(updatedColors);
    setTheme((prevTheme) => ({ ...prevTheme, Colors: updatedColors }));
  };

  useEffect(() => {
    if (colorProps) {
      //generateColorCss(colorProps);
      //const { accent, neutral } = colorProps;
      //updateCssVariables(accent, 'accent');
      //updateCssVariables(neutral, 'neutral');
    }
  }, [colorProps]);

  const { neutral, accent, red, green, white, transparent, ...otherProps } = colors;
  const accentProps = accent ? Object.entries(accent) : [];
  const neutralProps = neutral ? Object.entries(neutral) : [];
  const redProps = red ? Object.entries(red) : [];
  const greenProps = green ? Object.entries(green) : [];

  return (
    <div className="ui-colors">
      <div className="ui-pagetitel">Colors</div>
      <div className="ui-elements">
        <div className="ui-sectiontitel">Main colors</div>
        {Object.entries(otherProps).map(([key, value]) => (
          <div className="ui-colors-element" key={key}>
            <div style={{ backgroundColor: value }} className="ui-colors-indicator"></div>
            <div className="ui-form">
              <div className="ui-form-element" key={key}>
                <label className="ui-label">{camelToKebab(key)}</label>
                <input
                  className="ui-input"
                  name={key}
                  value={value}
                  onChange={(event) => handleChange(event, key)}
                />
              </div>
            </div>
          </div>
        ))}
        <div className="ui-sectiontitel">Neutral colors</div>
        {colors.neutral &&
          neutralProps.map(([key, value]) => (
            <div className="ui-colors-element" key={key}>
              <div style={{ backgroundColor: value }} className="ui-colors-indicator"></div>
              <div className="ui-form">
                <div className="ui-form-element" key={key}>
                  <label className="ui-label">{camelToKebab(key)}</label>
                  <input
                    className="ui-input"
                    name={key}
                    value={value}
                    onChange={(event) => handleChange(event, 'neutral', key)}
                  />
                </div>
              </div>
            </div>
          ))}
        <div className="ui-sectiontitel">Accent colors</div>
        {colors.accent &&
          accentProps.map(([key, value]) => (
            <div className="ui-colors-element" key={key}>
              <div style={{ backgroundColor: value }} className="ui-colors-indicator"></div>
              <div className="ui-form">
                <div className="ui-form-element" key={key}>
                  <label className="ui-label">{camelToKebab(key)}</label>
                  <input
                    className="ui-input"
                    name={key}
                    value={value}
                    onChange={(event) => handleChange(event, 'accent', key)}
                  />
                </div>
              </div>
            </div>
          ))}
        <div className="ui-sectiontitel">Red colors</div>
        {colors.red &&
          redProps.map(([key, value]) => (
            <div className="ui-colors-element" key={key}>
              <div style={{ backgroundColor: value }} className="ui-colors-indicator"></div>
              <div className="ui-form">
                <div className="ui-form-element" key={key}>
                  <label className="ui-label">{camelToKebab(key)}</label>
                  <input
                    className="ui-input"
                    name={key}
                    value={value}
                    onChange={(event) => handleChange(event, 'red', key)}
                  />
                </div>
              </div>
            </div>
          ))}
        <div className="ui-sectiontitel">Green colors</div>
        {colors.green &&
          greenProps.map(([key, value]) => (
            <div className="ui-colors-element" key={key}>
              <div style={{ backgroundColor: value }} className="ui-colors-indicator"></div>
              <div className="ui-form">
                <div className="ui-form-element" key={key}>
                  <label className="ui-label">{camelToKebab(key)}</label>
                  <input
                    className="ui-input"
                    name={key}
                    value={value}
                    onChange={(event) => handleChange(event, 'green', key)}
                  />
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default Colors;
