import React, { useRef, useState, useEffect } from 'react';
import { convertPropsToStyle } from '../utils';

const TypographyContent = ({ style, fontText }) => {
  const [styles, setStyles] = useState('');

  const iframeRef = useRef(null);

  useEffect(() => {
    const loadStyles = async (paths) => {
      const fetchPromises = paths.map((path) => fetch(path));
      const styleSheets = await Promise.all(fetchPromises);
      const cssTexts = await Promise.all(styleSheets.map((styleSheet) => styleSheet.text()));
      return cssTexts.join('\n');
    };

    const paths = [
      '../dist/css/theme.css',
      '../dist/css/index.css',
      '../dist/css/uncritical.css',
      '../dist/css/text.css',
    ];
    loadStyles(paths).then(setStyles);
  }, []);

  const [cssStyle, setCssStyle] = useState('');

  useEffect(() => {
    const newStyle = Object.entries(style)
      .map(([prop, value]) => `${prop}: ${value};`)
      .join(' ');
    setCssStyle(newStyle);
    const link = document.createElement('link');
    link.href = `https://fonts.googleapis.com/css2?family=${encodeURIComponent(
      fontText,
    )}:wght@100;200;300;400;500;600;700;800;900`;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  }, [style, fontText]);

  const iframeContent = `
    <style>
      ${styles}
       body {
        font-family: '${fontText}', sans-serif !important;
      }
    </style>
   <div class="text" style="${cssStyle}">
    <div class="text-headline">
        <h1 style="font-size: 2rem; font-weight: var(--weight-regular);">Weight regular</h1>
      </div>
      <div class="text-headline">
        <h1 style="font-size: 2rem; font-weight: var(--weight-medium);">Weight medium</h1>
      </div>
       <div class="text-headline">
        <h1 style="font-size: 2rem; font-weight: var(--weight-bold);">Weight bold</h1>
      </div>
       <div class="text-headline">
        <h1 style="font-size: 2rem; font-weight: var(--weight-extrabold);">Weight extrabold</h1>
      </div>
</div>
  `;

  useEffect(() => {
    if (iframeRef.current) {
      const iframe = iframeRef.current;
      const doc = iframe.contentDocument || iframe.contentWindow.document;
      doc.open();
      doc.write(iframeContent);
      doc.close();
      setTimeout(() => {
        iframe.style.height = `${doc.documentElement.scrollHeight}px`;
      }, 100);
    }
  }, [iframeContent]);

  return <iframe title="componentIframe" ref={iframeRef} srcDoc={iframeContent} />;
};

const Typography = (props) => {
  let storedProps;
  try {
    storedProps = JSON.parse(localStorage.getItem(`Typography`));
  } catch (error) {
    console.error('Error parsing stored props:', error);
  }
  const style = convertPropsToStyle(storedProps || props);

  const fontText = storedProps?.fontText || 'Nunito';
  return (
    <div className="ui-component-view">
      <div className="ui-component-inner">
        <div className="ui-pagetitel">Typography</div>
        <div className="ui-component-preview">
          <TypographyContent style={style} fontText={fontText} />
        </div>
      </div>
    </div>
  );
};

export const markup = `<style>
  --weight-regular: 400;
  --weight-medium: 500;
  --weight-bold: 700;
  --weight-extrabold: 900;
</style>
`;

export default Typography;
