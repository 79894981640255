import React, { useRef, useState, useEffect } from 'react';
import { convertPropsToStyle, getThemeVariables } from '../utils';

const ModalContent = ({ style, fontText }) => {
  const [styles, setStyles] = useState('');
  const [scripts, setScripts] = useState('');

  const iframeRef = useRef(null);

  useEffect(() => {
    const loadStyles = async (paths) => {
      const fetchPromises = paths.map((path) => fetch(path));
      const styleSheets = await Promise.all(fetchPromises);
      const cssTexts = await Promise.all(styleSheets.map((styleSheet) => styleSheet.text()));
      return cssTexts.join('\n');
    };

    const paths = [
      '../dist/css/theme.css',
      '../dist/css/index.css',
      '../dist/css/uncritical.css',
      '../dist/css/button.css',
      '../dist/css/modal.css',
    ];
    loadStyles(paths).then(setStyles);
  }, []);

  const [cssStyle, setCssStyle] = useState('');

  useEffect(() => {
    const loadScripts = async (paths) => {
      const fetchPromises = paths.map((path) => fetch(path));
      const scripts = await Promise.all(fetchPromises);
      const jsTexts = await Promise.all(scripts.map((script) => script.text()));
      return jsTexts.join('\n');
    };

    const paths = ['../dist/js/index.js'];
    loadScripts(paths).then(setScripts);
  }, []);

  useEffect(() => {
    const newStyle = Object.entries(style)
      .map(([prop, value]) => `${prop}: ${value};`)
      .join(' ');
    setCssStyle(newStyle);
    const link = document.createElement('link');
    link.href = `https://fonts.googleapis.com/css2?family=${encodeURIComponent(
      fontText,
    )}:wght@100;200;300;400;500;600;700;800;900`;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  }, [style, fontText]);

  const iframeContent = `
   <style>
      ${styles}
    </style>
     <script>
    ${scripts}
  </script>
  <script>
  </script>
   <div style="${cssStyle}">
<div id="modal" class="modal modal--open" style="display: flex !important;">
  <div class="modal-body">
    <div class="modal-header">
      <div class="modal-title">Modal titel</div>
      <button class="modal-close">
        <!-- Close --></button>
    </div>
    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, <strong>sed diam nonumy</strong> eirmod tempor invidunt ut <s>labore et dolore</s> magna aliquyam erat, <a href="#">sed diam voluptua</a>.</p>
  </div>
</div>
</div>
<div class="modal-body-background"></div>
  `;

  useEffect(() => {
    if (iframeRef.current) {
      const iframe = iframeRef.current;
      const doc = iframe.contentDocument || iframe.contentWindow.document;
      doc.open();
      doc.write(iframeContent);
      doc.close();
      getThemeVariables(doc.documentElement);
      setTimeout(() => {
        iframe.style.height = `400px`;
      }, 100);
    }
  }, [iframeContent]);

  return <iframe title="componentIframe" ref={iframeRef} srcDoc={iframeContent} />;
};

const Modal = (props) => {
  let storedProps;
  try {
    storedProps = JSON.parse(localStorage.getItem(`Modal`));
  } catch (error) {
    console.error('Error parsing stored props:', error);
  }
  const style = convertPropsToStyle(storedProps || props);
  return (
    <div className="ui-component-view">
      <div className="ui-component-inner">
        <div className="ui-pagetitel">Modal</div>
        <div className="ui-component-preview">
          <ModalContent style={style} />
        </div>
      </div>
    </div>
  );
};

export const markup = `<button class="modal-trigger" data-target="modal">Open modal</button>
<div id="modal" class="modal">
  <div class="modal-body">
    <div class="modal-header">
      <div class="modal-title">Modal titel</div>
      <button class="modal-close">
        <!-- Close -->
      </button>
    </div>
    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</p>
  </div>
</div>
</div>
`;

export default Modal;
